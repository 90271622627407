.logoBox{
    position: absolute;
    padding: 2vmax;
    display: flex;
    gap: 1rem;
    width: 20%;
    justify-content: space-between;
}
.BackBtn{
    width: 40px;

    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--bg1);
}
.BackBtn:hover{
    background-color: var(--bg1);
    color: white;
}
.bgbanner{
    background-image: linear-gradient(rgb(222, 252, 255), white);
}
