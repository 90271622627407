#modalBox{
    position: relative !important;
    z-index: 9999999 !important;
    border-radius: 0.4rem;

    position: 'absolute';
    top: 20%;
    left: 30%;
    transform: 'translate(-50%; -50%)';
    width: 40%;
    /*  border: '2px solid #000'; */
    background-color: white;
    boxShadow: 24;
    padding: 2vmax;
}
.helpFormBox{
    width: 100%;
    padding-top: 1vmax;


}
.helpFormBox > form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* background-color: aqua; */
}
.textArea,
.helpFormBox > form > input{
    padding: 0.8vmax;
    outline: none;
    /* border: 1px solid var(--textC1); */
    box-shadow: 0 0 5px rgb(198, 198, 198);
    border: none;
    border-radius: 0.4rem;
    font: 300 1vmax 'Roboto';
}
.textArea:focus,
.helpFormBox > form > input:focus{
    border: 1px solid var(--bg1);
    color: var(--bg1);
    

}