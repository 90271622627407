@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --bg1: #6098f2;
    --bg2: #61e5ff;

    --textC1: rgb(36, 36, 36);
    --textC2: white;
}

.aTag {
    text-decoration: none;
    color: var(--textC1);

}

.overflow-auto::-webkit-scrollbar {
    height: 8px;
    background-color: rgba(237, 237, 237, 0.614);
    border-radius: 2rem;
    overflow: hidden;
}

.overflow-auto::-webkit-scrollbar-thumb {
    height: 7px;
    background-color: #c5dde1cd;
    border-radius: 2rem;

}